import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Scroll from 'react-scroll';
import GTM from '../../../../utils/initialGTM';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';
import SiteSection from '../../../layouts/SiteLayout/SiteSection/SiteSection';
import LeadGenContainer from '../../../leadGen/LeadGenContainer';
import Advertising from '../../../shared/Advertising/Advertising';

export class LandingPageView extends Component {
  componentDidMount() {
    const {
      leadGenForm: { data: { gateId, leadGenType, contentType } = {} } = {},
    } = this.props;

    if (!leadGenType) {
      GTM.isContentGated(!!gateId);
      GTM.pushContentType(contentType);
    }

    if (window.pageYOffset > 0 && !document.location.hash) {
      Scroll.animateScroll.scrollToTop();
    }
  }

  render() {
    const {
      siteHeader: { meta: { title: defaultTitle } = {} } = {},
      siteContent: {
        data: { sections = [], name, noindexEnabled, title: leadGenTitle } = {},
        meta: { title: contentTitle } = {},
      } = {},
      leadGenForm: { loaded: leadGenLoaded } = {},
    } = this.props;

    const helmet = {
      title:
        leadGenLoaded && leadGenTitle
          ? `${leadGenTitle} | ${defaultTitle}`
          : contentTitle || `${name} | ${defaultTitle}`,
      meta: {
        robots: noindexEnabled ? 'noindex, nofollow' : 'index, follow',
      },
    };
    const middleIndex = Math.floor(sections.length / 2);

    return (
      <SiteLayout helmet={helmet}>
        {leadGenLoaded ? (
          <LeadGenContainer />
        ) : (
          sections.map((section, index) => (
            <>
              <SiteSection key={section.id} section={section} index={index} />
              {index === middleIndex && (
                <Advertising
                  advSlots={[{ slotId: '728_2' }]}
                  advSize="728_90"
                />
              )}
            </>
          ))
        )}
      </SiteLayout>
    );
  }
}

LandingPageView.propTypes = {
  siteHeader: PropTypes.shape({
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  siteContent: PropTypes.shape({
    data: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.object),
    }),
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  leadGenForm: PropTypes.shape({
    loaded: PropTypes.bool,
  }),
  options: PropTypes.shape({
    data: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    siteContent: state.siteContent,
    leadGenForm: state.leadGenForm,
    options: state.options,
  };
}

export default connect(mapStateToProps)(LandingPageView);
